import React, { useState } from 'react';
import AlbumModal from './AlbumModal';
import PgWrapper from './PgWrapper';
import { useExperiments, useBi } from '@wix/yoshi-flow-editor';
import { gogAlbumClick } from '@wix/bi-logger-photo-ugc/v2';
import { HOUR } from '../../../../consts/generalConsts';

export default function Gog(props: GogProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const { experiments } = useExperiments();
  const isRelocateGogIframeEnabled = experiments.enabled(
    'specs.albums.relocateGogIframe',
  );
  const biLogger = useBi();

  const isPremiumSite = (album: GogAlbum) => {
    return album.publishedLayout && !!album.publishedLayout.subDomainDate;
  };
  const isPremiumWithNewSubdomain = (album: GogAlbum) => {
    return (
      album.publishedLayout &&
      album.publishedLayout.subDomainDate &&
      album.publishedLayout.subDomainDate + HOUR > new Date().getTime()
    );
  };
  const getLink = (album: GogAlbum) => {
    let link;
    if (isPremiumWithNewSubdomain(album)) {
      link = album.publishedLayout.link;
    } else if (album.liveLink) {
      link = album.liveLink;
    } else if (album.publishedLayout.link) {
      link = album.publishedLayout.link;
    }
    return link;
  };
  const onAlbumClick = async (album: GogAlbum) => {
    biLogger.report(
      gogAlbumClick({
        biToken: props.biToken,
        album_id: album.albumId,
        app_site_id: props.instanceId,
        instance_id: props.instanceId,
        is_owner: props.userOwnership === 'Admin',
        visitor_id: props.visitorId,
      }),
    );
    const link = album && getLink(album);
    const _isPremiumSite = isPremiumSite(album) || false;
    if (link) {
      const albumClick = props.styleParams.numbers.albumClick;
      if (experiments.enabled('specs.albums.usePortfolioInsteadOfAlbums')) {
        window.location.href =
          'https://dorr42.wixsite.com/portfolio-in-albums/dynamic/' +
          album.albumId +
          window.location.search;
        return;
      }
      if (albumClick === 1 && !props.mobile && screen && screen.width >= 1200) {
        setCurrentLink(link);
        setIsModalOpen(true);
        setIsPremium(_isPremiumSite);
      } else {
        window.open(link);
      }
    }
  };
  const handleWixFreeBanner = () => {
    const myInterval = setInterval(() => {
      const iframeHolder = document.getElementById(
        'albumFrame',
      ) as HTMLObjectElement;
      const freeSiteBanner =
        iframeHolder?.contentDocument?.querySelector('#WIX_ADS');

      if (freeSiteBanner) {
        freeSiteBanner.setAttribute('style', 'display: none;');
        clearInterval(myInterval);
        console.log('Cleared');
      }
    }, 100);
  };
  if (isRelocateGogIframeEnabled) {
    isModalOpen && handleWixFreeBanner();
  }

  return (
    <>
      <PgWrapper
        galleryItems={props.albumsCovers}
        dimensions={props.dimensions}
        styleParams={props.styleParams}
        textPresets={props.textPresets}
        coverIdToAlbum={props.coverIdToAlbum}
        onAlbumClick={(album) => {
          onAlbumClick(album);
        }}
        isMobile={props.mobile}
        Wix={props.Wix}
        viewMode={props.viewMode}
        updateLayout={props.updateLayout}
        compId={props.compId}
        setHeight={props.setHeight}
        t={props.t}
        formFactor={props.formFactor}
        isSSR={props.isSSR}
        getDefaultAlbumCovers={props.getDefaultAlbumCovers}
      />
      <AlbumModal
        closeModal={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        link={currentLink}
        isRelocateGogIframeEnabled={isRelocateGogIframeEnabled}
      />
    </>
  );
}
