import React, { JSXElementConstructor } from 'react';

import { ProGallery, GALLERY_CONSTS } from 'pro-gallery';
import {
  resizeMediaUrl,
  resizeMediaUrlForOldGoG,
} from '../../../../services/itemResizer';
import AlbumInfo from './AlbumInfo';
import {
  defaultPGStyleParams,
  gogSpecificGalleryStyleParams,
  gogNewVersionDefaultsStyleParams,
} from '../../../../services/styleParams/defaultStyleParams';
import { albumsStylesToPGStyles } from '../../../../services/styleParams/converter';
import {
  TPADimensionsHelper,
  getProGalleryStyles,
} from '@wix/photography-client-lib';
import DimensionsHelper from '../../../../services/dimensionsHelper';
import './wixStyles.global.scss';
import {
  parseViewMode,
  parseFormFactor,
} from '../../../../services/galleryHelper';
import {
  EXTERNAL_INFO_TYPE,
  InfoElement as ProGalleryInfoElement,
} from '@wix/pro-gallery-info-element';
import 'pro-gallery/dist/statics/main.css';

export default class PgWrapper extends React.Component<
  PgWrapperProps,
  PgWrapperState
> {
  shouldResize: boolean;
  dimensionsHelper: any;
  galleryHeight: number | undefined;
  oneRowFromGalleryStructure: any;

  constructor(props: PgWrapperProps) {
    super(props);
    const initialDimensions = {
      width: typeof window !== 'undefined' ? 980 : 0,
      height: 500,
    };

    console.log({ initialDimensions });

    this.state = {
      container: {
        width: this.props.dimensions.width || initialDimensions.width,
        height: this.props.dimensions.height || initialDimensions.height,
        avoidGallerySelfMeasure: true,
      },
      lastViewMode: this.props.viewMode,
      defaultAlbums: [],
    };

    console.log({ PGDimensions: this.state.container, props: this.props });
    this.handleEvent = this.handleEvent.bind(this);
    this.shouldResize = !this.props.dimensions.width;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.setHeightImp = this.setHeightImp.bind(this);
    this.handleNewGalleryStructure = this.handleNewGalleryStructure.bind(this);
    this.dimensionsHelper = new DimensionsHelper(this, props);
    this.createContainer = this.createContainer.bind(this);
    this.getScrollingElement = this.getScrollingElement.bind(this);
  }

  setHeightImp = (newHeight?: number) => {
    // need to check every time since it can be changed when switching between editor/preview
    if (this.props.Wix && typeof this.props.Wix.setHeight === 'function') {
      this.props.Wix.setHeight(newHeight);
    } else {
      typeof this.props.setHeight === 'function' &&
        this.props.setHeight(newHeight);
    }
    this.setState({
      container: { ...this.state.container, height: newHeight },
    });
  };

  handleNewGalleryStructure(newGalleryStructureData: any) {
    const {
      numOfItems,
      container,
      layoutHeight,
      updatedHeight,
      styleParams,
      isInfinite,
    } = newGalleryStructureData;

    // add hack for changing from editor to preview
    this.oneRowFromGalleryStructure = styleParams;
    const galleryHeight = TPADimensionsHelper.setWixHeight({
      height: layoutHeight,
      offsetTop: 0,
      container,
      numOfItems,
      updatedHeight,
      isInfinite,
      setHeightImp: this.setHeightImp,
      viewMode: this.props.viewMode,
      styleParams,
      clearHeight: this.props.Wix && this.props.Wix.clearHeight,
    });

    try {
      this.props.updateLayout && this.props.updateLayout();
    } catch (e: any) {
      console.log('Cannot call updateLayout', e);
    }
  }

  getScrollingElement() {
    try {
      if (
        typeof window !== 'undefined' &&
        (window.top === window.self || !this.props.Wix)
      ) {
        // OOI
        return window;
      } else {
        // iFrame
        // return a "mock" of the window
        return {
          addEventListener: (eventName: string, callback: any) => {
            this.props.Wix.addEventListener(eventName.toUpperCase(), callback);
          },
          removeEventListener: (eventName: string, callback: any) => {
            this.props.Wix.removeEventListener(
              eventName.toUpperCase(),
              callback,
            );
          },
        };
      }
    } catch (e: any) {
      console.error('Cannot get scrolling element', e);
      return {};
    }
  }

  createContainer() {
    this.props.Wix &&
      this.props.Wix.getBoundingRectAndOffsets((rect: any) => {
        this.setState({
          container: {
            scrollBase: rect.offsets.y * rect.scale || 0,
            width: TPADimensionsHelper.protectGalleryWidth(
              this.props.isMobile
                ? document.body.clientWidth
                : window.innerWidth,
            ),
            height: TPADimensionsHelper.protectGalleryHeight(
              window.innerHeight,
              0,
            ),
          },
        });
      });
  }

  handleEvent(eName: string, eData: any) {
    if (eName === GALLERY_CONSTS.events.ITEM_ACTION_TRIGGERED) {
      if (typeof this.props.onAlbumClick === 'function') {
        this.props.onAlbumClick(this.props.coverIdToAlbum[eData.id]);
      }
    }
    if (eName === GALLERY_CONSTS.events.GALLERY_CHANGE) {
      this.handleNewGalleryStructure(eData);
    }
  }

  async componentDidMount() {
    if (this.props.viewMode === 'Editor') {
      // this.setHeightImp(this.state.container.height); // check if necessary
      this.createContainer();
    } else {
      this.dimensionsHelper.setParentDidMount();
      this.dimensionsHelper.createResizeObserver();
      this.dimensionsHelper.createIntersectionObserver();
      this.updateDimensions();
    }

    const defaultCovers = await this.props.getDefaultAlbumCovers(true);
    if (defaultCovers) {
      this.setState({ defaultAlbums: defaultCovers });
    }
  }

  async componentWillReceiveProps(nextProps: any) {
    if (nextProps.viewMode === 'Editor') {
      this.createContainer();
    } else {
      this.updateDimensions(nextProps);
    }
  }

  updateDimensions(newProps?: any) {
    this.dimensionsHelper.update(newProps || this.props);
  }

  getInfoElement(type: string) {
    return (itemProps: any) => {
      return (
        <ProGalleryInfoElement
          infoType={type}
          {...itemProps}
          options={itemProps.styleParams}
        />
      );
    };
  }

  getInfoElements() {
    return {
      customInfoRenderer: this.getInfoElement(EXTERNAL_INFO_TYPE.EXTERNAL),
      customHoverRenderer: this.getInfoElement(EXTERNAL_INFO_TYPE.HOVER),
      customSlideshowInfoRenderer: this.getInfoElement(
        EXTERNAL_INFO_TYPE.SLIDESHOW,
      ),
    };
  }

  checkIfStyleParamsEmpty = (currentSP: any) => {
    if (
      currentSP &&
      Object.keys(currentSP.booleans).length < 2 &&
      Object.keys(currentSP.numbers).length === 0 &&
      Object.keys(currentSP.fonts).length === 0 &&
      Object.keys(currentSP.colors).length === 0
    ) {
      return true;
    }

    return false;
  };

  isFirstAndInstallFromMarket = (currentSP: any) => {
    return this.checkIfStyleParamsEmpty(currentSP);
  };

  render() {
    const container = this.state.container;
    const firstAndInstallFromMarket = this.isFirstAndInstallFromMarket(
      this.props.styleParams,
    );
    const allowProGalleryStyleParams =
      firstAndInstallFromMarket ||
      (this.props.styleParams.booleans &&
        this.props.styleParams.booleans.isProGalleryGoG);
    // isProGallyGoG - a style param that indicates that gog was updated
    // in settings to receive pro gallery style params.
    // firstAndInstallFromMarket -
    // gog is already updated upon installation from app market and should get Pro Gallery default style params

    let InfoElement: JSXElementConstructor<ItemProps>;
    let galleryStyleParams: any;
    const additionalPgProps: PGProps = {};
    let resizeMediaUrlFunc;
    if (allowProGalleryStyleParams) {
      if (firstAndInstallFromMarket) {
        galleryStyleParams = {
          // convert editor styles to pg styles
          ...getProGalleryStyles(gogNewVersionDefaultsStyleParams),
        };
      } else {
        galleryStyleParams = {
          // convert editor styles to pg styles
          ...getProGalleryStyles(this.props.styleParams),
        };
      }

      // ensure pg social styles are set to false
      galleryStyleParams = {
        ...galleryStyleParams,
        ...gogSpecificGalleryStyleParams,
      };

      resizeMediaUrlFunc = resizeMediaUrl;
      Object.assign(additionalPgProps, this.getInfoElements());
    } else {
      // If gog wasn't updated, use AlbumInfo in order to recreate old gog's info element (albums-statics)
      // In addition,  convert old albums style params to pro gallery style params so that the old
      // gog and the pro gallery will look the same
      galleryStyleParams = albumsStylesToPGStyles(this.props.styleParams);
      InfoElement = (itemProps: ItemProps) => (
        <AlbumInfo
          key={itemProps.id}
          dimensions={{ width: itemProps.style.width }}
          data={this.props.coverIdToAlbum[itemProps.id]}
          styleParams={galleryStyleParams}
          textPresets={this.props.textPresets}
          t={this.props.t}
        />
      );

      resizeMediaUrlFunc = resizeMediaUrlForOldGoG;
      additionalPgProps.customInfoRenderer = (itemProps: ItemProps) => {
        return <InfoElement {...itemProps} />;
      };
    }

    const viewMode = parseViewMode(this.props.viewMode);
    const formFactor = parseFormFactor(this.props.formFactor);

    return (
      <div
        id={'gallery-wrapper-' + this.props.compId}
        className={allowProGalleryStyleParams ? 'pgGog' : ''}
      >
        <ProGallery
          domId={this.props.compId}
          allowSSR={true}
          items={
            this.props.galleryItems.length > 0
              ? this.props.galleryItems
              : this.state.defaultAlbums
          }
          resizeMediaUrl={resizeMediaUrlFunc}
          eventsListener={this.handleEvent}
          container={container}
          scrollingElement={this.getScrollingElement}
          viewMode={viewMode}
          formFactor={formFactor}
          options={{
            ...defaultPGStyleParams,
            ...galleryStyleParams,
          }}
          {...additionalPgProps}
        />
      </div>
    );
  }
}
