import React, { useState, useEffect } from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { gogLoaded } from '@wix/bi-logger-photo-ugc/v2';
import Gog from './GogOOI/Gog';
import {
  getSortedAlbums,
  orderIndexSorter,
} from '../../../services/albumsSorter';
import { useExperiments, useBi } from '@wix/yoshi-flow-editor';

type GogWrapperProps = InjectedTranslateProps & WidgetProps;

export default function GogWrapper(props: GogWrapperProps) {
  const { experiments } = useExperiments();
  const biLogger = useBi();
  const isSortAlbumsEnabled = experiments.enabled('specs.albums.sortGogAlbums');
  const [gogData, setGogData] = useState(props.gogData);
  const [albumsCovers, setAlbumsCovers] = useState(
    isSortAlbumsEnabled
      ? getSortedAlbums(
          props.host.style,
          props.albumsCovers,
          props.coverIdToAlbum,
        )
      : props.albumsCovers.sort(orderIndexSorter),
  );
  const [coverIdToAlbum, setCoverIdToAlbum] = useState(props.coverIdToAlbum);
  const handleWixEvent = (event: any) => {
    if (event.action === 'albumsData') {
      try {
        const gogAlbums: GogAlbum[] = event.data;
        const { albumsCovers: _albumsCovers, coverIdToAlbum: _coverIdToAlbum } =
          props.parseAlbumsCovers(gogAlbums);
        setGogData({ ...gogData, albums: gogAlbums });
        setAlbumsCovers(
          getSortedAlbums(props.host.style, _albumsCovers, _coverIdToAlbum),
        );
        setCoverIdToAlbum(_coverIdToAlbum);
      } catch (e: any) {
        console.error('Failed to update gog ' + e);
      }
    }
  };

  useEffect(() => {
    if (props.viewMode === 'Editor') {
      props.Wix &&
        props.Wix.addEventListener(
          props.Wix.Events.SETTINGS_UPDATED,
          handleWixEvent,
        );
    }
    biLogger.report(
      gogLoaded({
        biToken: props.biToken,
        instance_id: props.instanceId,
        is_editor: props.viewMode === 'Editor',
        is_owner: props.userOwnership === 'Admin',
        visitor_id: props.visitorId,
      }),
    );
  }, []);

  const styleParams = props.host.style.styleParams;

  const gogProps = {
    ...props,
    pageId: props.host.pageId,
    compId: props.host.id,
    dimensions: props.host.dimensions,
    styleParams,
    textPresets: props.host.style.siteTextPresets,
    gogData,
    albumsCovers,
    coverIdToAlbum,
    formFactor: props.host.formFactor,
    isSSR: props.isSSR,
    getDefaultAlbumCovers: props.getDefaultAlbumCovers,
  };

  return (
    <div className="albums-gog-wrapper" data-hook="AlbumsGOGOOI-wrapper">
      <Gog {...gogProps} />
    </div>
  );
}
