import React from 'react';
import { I18nextProvider, translate } from 'react-i18next';

import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { WixSDK, useEnvironment } from '@wix/yoshi-flow-editor';
import i18n from '../../../config/i18n';
import GogWrapper from './GogWrapper';
import './wixStyles.global.scss';

interface WidgetWrapperProps {
  visitorId: string;
  userOwnership: string;
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  host: any;
  instance: string;
  instanceId: string;
  biToken: string;
  updateLayout: any;
  viewMode: string;
  setHeight: any;
  albumsCovers: GalleryItem[];
  coverIdToAlbum: { [id: string]: GogAlbum };
  gogData: GogData;
  fetchAlbums: () => Promise<{
    gogData: any;
    albumsCovers: GalleryItem[];
    coverIdToAlbum: {
      [id: string]: GogAlbum;
    };
  }>;
  parseAlbumsCovers: (albums: GogAlbum[]) => {
    albumsCovers: GalleryItem[];
    coverIdToAlbum: {
      [id: string]: GogAlbum;
    };
  };
  getDefaultAlbumCovers: (isNewDefaultAlbums: boolean) => GalleryItem[];
  isSSR?: boolean;
  experiments?: any;
}

export default ({
  visitorId,
  userOwnership,
  mobile,
  host,
  instance,
  instanceId,
  biToken,
  updateLayout,
  setHeight,
  albumsCovers,
  coverIdToAlbum,
  gogData,
  fetchAlbums,
  parseAlbumsCovers,
  getDefaultAlbumCovers,
  language,
  translations,
}: WidgetWrapperProps) => {
  // Fix For Editor Preview Mode
  // Need it only when the GoG is set to stretch
  if (host.dimensions.width === '100%') {
    host.dimensions.width = '';
  }

  const { isSSR, isMobile } = useEnvironment();

  return (
    <TPAComponentsProvider value={{ mobile }}>
      <I18nextProvider i18n={i18n({ language, translations })}>
        <WixSDK>
          {(sdk) => (
            <Widget
              visitorId={visitorId}
              userOwnership={userOwnership}
              Wix={sdk.Wix}
              host={host}
              instance={instance}
              instanceId={instanceId}
              biToken={biToken}
              mobile={isMobile}
              updateLayout={updateLayout}
              viewMode={host.viewMode}
              setHeight={setHeight}
              albumsCovers={albumsCovers}
              gogData={gogData}
              coverIdToAlbum={coverIdToAlbum}
              fetchAlbums={fetchAlbums}
              parseAlbumsCovers={parseAlbumsCovers}
              getDefaultAlbumCovers={getDefaultAlbumCovers}
              isSSR={isSSR}
            />
          )}
        </WixSDK>
      </I18nextProvider>
    </TPAComponentsProvider>
  );
};

export const Widget = translate()(GogWrapper);
